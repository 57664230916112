import { Box, Container, Flex, Img } from '@chakra-ui/react';
import * as React from 'react';

import LoginLogoutButton from '@/components/general/LoginLogoutButton';
import BaseLink from '@/components/links/BaseLink';

export interface HeaderProps {}

/**
 * Header for authenticated user
 * @date 9/16/2022 - 1:44:42 PM
 * @author Indra Lukmana
 *
 */
const Header: React.FC<HeaderProps> = () => {
	return (
		<Box bg="green.800">
			<Container
				maxW="6xl"
				py={10}
				as="header"
				display="flex"
				justifyContent="space-between"
			>
				<Flex alignItems="center">
					<BaseLink href="/">
						<Flex align="center">
							<Img h="60px" src="/assets/logoheader.png" />
						</Flex>
					</BaseLink>
				</Flex>
				<Flex align="center">
					<LoginLogoutButton />
				</Flex>
			</Container>
		</Box>
	);
};

export default Header;
