import {
	AspectRatio,
	Box,
	Container,
	Flex,
	Heading,
	Img,
	Stack,
	Text,
} from '@chakra-ui/react';
import { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth';
import * as React from 'react';

import NonAuthBaseLayout from '@/components/layouts/non-auth/BaseLayout';
import ButtonLink from '@/components/links/ButtonLink';

import { authOptions } from '@/pages/api/auth/[...nextauth]';
import { NextPageWithLayout } from '@/pages/page';

import { UserRole } from '@/types/Role';

export const getServerSideProps = async (
	context: GetServerSidePropsContext
) => {
	try {
		const session = await getServerSession(
			context.req,
			context.res,
			authOptions
		);

		const role = session?.defaultUserRole;

		const returnObject = {
			redirect: {
				destination: '/',
				permanent: false,
			},
		};

		switch (role) {
			case UserRole.StafMadrasah:
			case UserRole.BendaharaMadrasah:
				returnObject.redirect.destination = '/madrasah';
				return returnObject;

			case UserRole.KepalaMadrasah:
				returnObject.redirect.destination = '/kepala-madrasah';
				return returnObject;

			case UserRole.Pengawas:
				returnObject.redirect.destination = '/pengawas';
				return returnObject;

			case UserRole.KabKota:
				returnObject.redirect.destination = '/kabupaten';
				return returnObject;

			case UserRole.Provinsi:
				returnObject.redirect.destination = '/provinsi';
				return returnObject;

			case UserRole.Pusat:
				returnObject.redirect.destination = '/pusat';
				return returnObject;

			default:
				break;
		}
	} catch (error) {
		return {
			props: {
				session: null,
			},
		};
	}

	return {
		props: {
			session: null,
		},
	};
};

/**
 * Home page component for the site
 *
 * @date 9/13/2022 - 1:56:34 PM
 * @returns {IndexPage}
 */
const IndexPage: NextPageWithLayout = () => {
	return (
		<Box>
			<Container
				maxW="full"
				bg="green.800"
				h="calc(100vh)"
				centerContent
				backgroundImage="url('../assets/header_chart_image.png')"
				backgroundRepeat="no-repeat"
				backgroundSize="100%"
				backgroundPosition="bottom"
			>
				<Stack spacing={12} align="center" maxW="5xl" py={28}>
					<Heading as="h1" fontSize="6xl" fontWeight="bold" textAlign="center">
						Evaluasi Mandiri Peningkatan Mutu Madrasah Nasional
					</Heading>
					<Text fontSize="2xl">
						Peningkatan kualitas madrasah dimulai dengan Evaluasi diri Madrasah
						yang akuntabel
					</Text>
					<ButtonLink
						href="/login"
						buttonProps={{
							w: '414px',
							h: '60px',
							bg: 'kemenag.500',
							color: 'white',
							_hover: {
								bg: 'teal',
							},
						}}
					>
						Masuk - Madrasah MA, Mts, MI, MA
					</ButtonLink>
				</Stack>
			</Container>
			<Container maxW="4xl" centerContent>
				<Flex
					h="540px"
					w="100%"
					py={14}
					align="center"
					justify="center"
					bg="green.900"
					rounded="3xl"
					mt="-210"
				>
					<Flex bg="green.900" w="50%">
						<Stack color="white" ml="20">
							<Stack spacing={-5} mb="4">
								<Text fontSize="3xl" w="400px" fontWeight="bold">
									Evaluasi Madrasah
								</Text>
								<Text fontSize="3xl" w="400px" fontWeight="bold" py="2">
									Secara Online.
								</Text>
							</Stack>
							<ButtonLink
								buttonProps={{
									w: '200px',
									py: '6',
									borderWidth: '1px',
									bg: '#kemenag.500',
									_hover: {
										bg: 'teal.500',
									},
								}}
								href="/login"
							>
								Evaluasi Sekarang
							</ButtonLink>
						</Stack>
					</Flex>
					<Flex w="50%">
						<Img
							rounded="3xl"
							ml="12"
							h="415px"
							w="290px"
							src="../images/meeting-photo-login-page.png"
							opacity="50%"
							my="12"
						/>
					</Flex>
				</Flex>
			</Container>
			<Container maxW="5xl" centerContent>
				<Box py={20} mb={5}>
					<Stack spacing={-2} fontSize="3xl" align="center" fontWeight="bold">
						<Text>
							Yuk ikuti Evaluasi Madrasah Secara Online, Tingkatkan Mutu
						</Text>
						<Text>Pendidikan Madrasah Bersama.</Text>
					</Stack>
				</Box>
				<Flex>
					<Flex w="50%" flexDir="column" px={12}>
						<Text fontSize="7xl" fontWeight="bold" color="green.500">
							01
						</Text>
						<Stack spacing={-2} mb="4">
							<Text fontSize="2xl" fontWeight="bold">
								Siapkan data pendukung dan isi
							</Text>
							<Text fontSize="2xl" fontWeight="bold">
								evaluasi dengan mudah
							</Text>
						</Stack>
						<Text pr="24px">
							persiapan data pendukung dapat membatu madrasah memberikan
							evaluasi diri yang lebih akuran dan kegiatan yang tepat
						</Text>
					</Flex>
					<Flex w="50%" px={4}>
						<Img w="100%" src="../assets/lp_image01.png" />
					</Flex>
				</Flex>
				<Flex my={10}>
					<Flex w="50%" px={4}>
						<Img w="100%" src="../assets/lp_image02.png" />
					</Flex>
					<Flex w="50%" flexDir="column" pr={24} pl={4}>
						<Text fontSize="7xl" fontWeight="bold" color="yellow.500">
							02
						</Text>
						<Text fontSize="2xl" fontWeight="bold" mb="4">
							Evaluasi Madrasah di EDM
						</Text>
						<Text pr="24px">
							Lakukan evaluasi diri dengan dukungan data sekunder dari berbagai
							sumber data.
						</Text>
					</Flex>
				</Flex>
				<Flex my={10}>
					<Flex w="50%" flexDir="column" pr={24} pl={4}>
						<Text fontSize="7xl" fontWeight="bold" color="green.500">
							03
						</Text>
						<Stack spacing={-2} mb="4">
							<Text fontSize="2xl" fontWeight="bold">
								Hasil evaluasi diri madrasah
							</Text>
							<Text fontSize="2xl" fontWeight="bold">
								akan menjadi dasar kegiatan
							</Text>
							<Text fontSize="2xl" fontWeight="bold">
								di RKAM
							</Text>
						</Stack>
						<Text pr="24px">
							dengan mengisi evaluasi, madrasah akan mendapatkan bantuan dana
							untuk meningkatkan kualitas
						</Text>
					</Flex>
					<Flex w="50%" px={4}>
						<Img w="100%" src="../assets/lp_image03.png" />
					</Flex>
				</Flex>
			</Container>
			<Container maxW="5xl" mt="24">
				<Stack
					spacing={-3}
					fontSize="4xl"
					fontWeight="bold"
					textAlign="left"
					mb="14"
				>
					<Text>Butuh bantuan ?</Text>
					<Text>Lihat dulu video di bawah</Text>
				</Stack>
				<Box>
					<AspectRatio ratio={16 / 9}>
						<iframe
							width="20%"
							height="20%"
							src="https://www.youtube.com/embed/sJEBwnm2l0M"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</AspectRatio>
				</Box>
			</Container>
			<Container maxW="full" bg="green.800" centerContent mt="-200">
				<Flex
					justifyContent="space-between"
					align="center"
					textAlign="left"
					pt="280"
					pb="50"
					w="5xl"
				>
					<Stack
						fontSize="4xl"
						w="500px"
						textAlign="left"
						mb={12}
						fontWeight="bold"
						spacing={-3}
					>
						<Text>Buku Manual untuk</Text>
						<Text>membantu pengisian EDM</Text>
					</Stack>

					<ButtonLink
						href="/madrasah/docs/halaman-panduan"
						buttonProps={{
							w: '333px',
							h: '60px',
							bg: 'kemenag.500',
							color: 'white',
							_hover: {
								bg: 'green.600',
							},
						}}
					>
						Lihat Buku Manual
					</ButtonLink>
				</Flex>
			</Container>
		</Box>
	);
};

export default IndexPage;

IndexPage.getLayout = (page) => {
	return (
		<NonAuthBaseLayout pageTitle="Evaluasi Diri Madrasah">
			{page}
		</NonAuthBaseLayout>
	);
};
