import { Box } from '@chakra-ui/react';
import Head from 'next/head';
import * as React from 'react';

import { Footer } from '@/components/layouts/non-auth/Footer';
import Header from '@/components/layouts/non-auth/Header';

export interface AppLayoutProps {
	children: React.ReactNode;
	pageTitle?: string;
}

const BaseLayout: React.FC<AppLayoutProps> = ({
	children,
	pageTitle = '~Page Title~',
}) => {
	// Put Header or Footer Here
	return (
		<Box
			display="grid"
			w="full"
			minH="100vh"
			gridTemplateRows="min-content 1fr min-content"
		>
			<Head>
				<title>{pageTitle}</title>
			</Head>
			<Header />
			<main>{children}</main>
			<Footer />
		</Box>
	);
};

export default BaseLayout;
