import {
	Link as ChakraLink,
	LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import * as React from 'react';

export interface BaseLinkProps extends Omit<ChakraLinkProps, 'href'> {
	href: NextLinkProps['href'];
	children: React.ReactNode;
	openNewTab?: boolean;
	className?: string;
	nextLinkProps?: Omit<NextLinkProps, 'href'>;
}

const BaseLink = React.forwardRef<HTMLAnchorElement, BaseLinkProps>(
	({ children, href, openNewTab, nextLinkProps, ...rest }, ref) => {
		const isNewTab =
			openNewTab !== undefined
				? openNewTab
				: typeof href === 'string' &&
				  !href.startsWith('/') &&
				  !href.startsWith('#');

		if (!isNewTab) {
			return (
				<NextLink href={href} {...nextLinkProps}>
					{children}
				</NextLink>
			);
		}

		if (typeof href === 'string') {
			return (
				<ChakraLink
					ref={ref}
					{...rest}
					target="_blank"
					rel="noopener noreferrer"
					href={href}
				>
					{children}
				</ChakraLink>
			);
		}

		return <>{children}</>;
	}
);

export default BaseLink;
