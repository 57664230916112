import { Button } from '@chakra-ui/react';
import { signOut, useSession } from 'next-auth/react';
import * as React from 'react';

import ButtonLink from '@/components/links/ButtonLink';

export interface LoginLogutButtonProps {}

/**
 * Authentication button component for login or logout depending on
 * session state
 * @date 9/21/2022 - 2:56:55 PM
 * @author Indra Lukmana
 *
 * @returns {*}
 */
const LoginLogoutButton: React.FC<LoginLogutButtonProps> = () => {
	const { status } = useSession();

	if (status === 'authenticated') {
		return (
			<Button onClick={() => signOut({ callbackUrl: '/login' })}>Keluar</Button>
		);
	}

	return (
		<ButtonLink
			href="/login"
			buttonProps={{
				w: '135px',
				h: '45px',
				bg: 'kemenag.500',
				color: 'white',
				_hover: {
					bg: 'teal',
				},
			}}
		>
			Masuk
		</ButtonLink>
	);
};

export default LoginLogoutButton;
