import {
	Box,
	Container,
	Flex,
	HStack,
	Icon,
	Img,
	SimpleGrid,
	Stack,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';

import BaseLink from '@/components/links/BaseLink';

/**
 * @date 9/13/2022 - 1:56:34 PM
 * @returns {Footer}
 */
export const Footer = (): React.ReactElement => {
	return (
		<Box bg="green.800">
			<Container maxW="6xl">
				<Flex
					align="center"
					pt="125"
					borderTopWidth="1.5px"
					borderColor="border.200"
					pb="10"
				>
					<Img px={2} h="47px" src="/logos/madrasah-reform-complete-logo.png" />
					<Img px={2} h="47px" src="/logos/kemenag-logo.png" />
				</Flex>
				<SimpleGrid
					columns={[2, null, 3]}
					spacing="40px"
					pt="2"
					borderBottomWidth="1.5px"
					borderColor="border.200"
					pb="16"
				>
					<Box>
						<Text fontWeight="bold" py={2}>
							Tentang Kami
						</Text>
						<Text py={2}>
							Direktorat Kurikulum, Sarana, Kelembagaan, dan Kesiswaan Madrasah
						</Text>
						<Text py={2}>
							Gedung Kementerian Agama RI Lt. 6, Jalan Lapangan Banteng Barat
							No. 3-4 Jakarta
						</Text>
					</Box>
					<Stack pl="8">
						<Text fontWeight="bold" py={2}>
							Link Terkait
						</Text>
						<BaseLink
							href="https://kemenag.go.id/"
							_hover={{ color: 'green.400', textDecoration: 'underline' }}
						>
							Kementrian Agama RI
						</BaseLink>
						<BaseLink
							href="https://madrasah.kemenag.go.id/"
							_hover={{ color: 'green.400', textDecoration: 'underline' }}
						>
							Direktorat KSKK Madrasah
						</BaseLink>
						<BaseLink
							href="https://erkam.kemenag.go.id/#/passport/login"
							_hover={{ color: 'green.400', textDecoration: 'underline' }}
						>
							E-RKAM Madrasah
						</BaseLink>
						<BaseLink
							href="https://emispendis.kemenag.go.id/dashboard/"
							_hover={{ color: 'green.400', textDecoration: 'underline' }}
						>
							EMIS
						</BaseLink>
					</Stack>
					<Box>
						<Text fontWeight="bold" py={2}>
							Kontak Kami
						</Text>
						<Text>0811-1968-6999 (whatsapp only)</Text>
						<Text>helpdesk.madrasah@kemenag.go.id</Text>
					</Box>
				</SimpleGrid>
				<Flex py={16} justify="space-between">
					<Text fontWeight="bold">
						&copy; Copyright Kementerian Agama RI 2019 - 2022
					</Text>
					<HStack spacing={8}>
						<BaseLink href="https://www.youtube.com/channel/UC5hpw-qs3CRxVfyaGY1CZiQ">
							<Icon as={FaYoutube} w={6} h={6} />
						</BaseLink>
						<BaseLink href="https://www.instagram.com/madrasahreform/">
							<Icon as={FaInstagram} w={6} h={6} />{' '}
						</BaseLink>
						<BaseLink href="https://web.facebook.com/MadrasahQualityReform/?_rdc=1&_rdr">
							<Icon as={FaFacebook} w={6} h={6} />
						</BaseLink>
						<BaseLink href="https://api.whatsapp.com/send?phone=6281147402020">
							<Icon as={FaWhatsapp} w={6} h={6} />
						</BaseLink>
					</HStack>
				</Flex>
			</Container>
		</Box>
	);
};
