import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

import BaseLink, { BaseLinkProps } from '@/components/links/BaseLink';

export interface ButtonLinkProps extends BaseLinkProps {
	buttonProps?: ButtonProps;
}

const ButtonLink: React.FC<React.PropsWithChildren<ButtonLinkProps>> = ({
	children,
	buttonProps,
	...rest
}) => {
	if (buttonProps?.disabled || buttonProps?.isLoading) {
		return (
			<Button
				{...buttonProps}
				disabled={buttonProps.isLoading ? true : buttonProps.disabled}
			>
				{children}
			</Button>
		);
	}

	return (
		<BaseLink {...rest} _hover={{ textDecoration: 'none' }}>
			<Button {...buttonProps}>{children}</Button>
		</BaseLink>
	);
};

export default ButtonLink;
